<template>
  <div id="ejcon" class="ejcon"  ref="appRef">
    <div class="bg">
      <dv-loading v-if="loading">Loading...</dv-loading>
      <div v-else class="host-body">
<!-- 头部 -->
         <div class="top-header">
            <dv-decoration-8 class="header-left-decoration" />
            <dv-decoration-5 class="header-center-decoration" dur="3" />
            <dv-decoration-8 class="header-right-decoration" :reverse="true" />
            <div class="center-title">运动项目历年优秀成绩展示</div>
          </div>
<div class="return" @click="returnBack">
            <img src="@/assets/return.png" alt="">
          </div>

  <!-- 年级 -->
  <div class="classInfo">
    <div class="classInfo_Item" v-for="(item,index) in array" :key="index">
      <dv-border-box-13>
        <h3 class="title fixed1">{{item.title}}</h3>
        <div class="classInfo_Item_con">
 <dv-scroll-board class="dv-scr-board" :config="item.config" />
         
          
        </div>
      </dv-border-box-13>
      
    </div>
  </div>
       
      </div>
    </div>
  </div>
</template>

<script>
import drawMixin from "../../utils/drawMixin";


export default {
  mixins: [ drawMixin ],
  data() {
    return {
      timing: null,
      loading: true,
      digitalFlopData: [],
      array: [{title: '一分钟跳绳历年最好成绩',config: {
        header: ['<span style="color:#9abeff;font-weight:bold">年份</span>', 
        '<span style="color:#9abeff;font-weight:bold;">所在班级</span>', 
        '<span style="color:#9abeff;font-weight:bold">指导老师</span>',
        '<span style="color:#9abeff;font-weight:bold">学生姓名</span>',
        '<span style="color:#9abeff;font-weight:bold">成绩</span>'],
        data: [
          ['2023年', '五年级（3）班', "赵晓峰","何海博", "104个"],
          ['2022年', '三年级（2）班', "张兰","张琪", "101个"],
          ['2021年', '六年级（3）班', "张琪","康宇轩", "140个"],
          ['2020年', '六年级（1）班', "张琪","刘晨皓", "123个"],
          ['2019年', '四年级（5）班', "赵晓峰","任子川", "122个"],
          ['2018年', '五年级（3）班', "张琪","王浩嘉", "132个"],
          ['2017年', '五年级（2）班', "张琪","王文谦", "121个"],
          ['2016年', '三年级（4）班', "张兰","魏梓晨", "109个"],
        ],
        rowNum: 5, //表格行数
        headerHeight: 54,
        headerBGC: '#072652', //表头
        oddRowBGC:'#06153c',
        evenRowBGC:'#06153c',
        align: ['left','left'],
        columnWidth: [94,160],
      }},{title: '坐位体前屈历年最好成绩',config: {
        header: ['<span style="color:#9abeff;font-weight:bold">年份</span>', 
        '<span style="color:#9abeff;font-weight:bold;">所在班级</span>', 
        '<span style="color:#9abeff;font-weight:bold">指导老师</span>',
        '<span style="color:#9abeff;font-weight:bold">学生姓名</span>',
        '<span style="color:#9abeff;font-weight:bold">成绩</span>'],
        data: [
          ['2023年', '三年级（3）班', "张琪","王文胤", "20cm"],
          ['2022年', '三年级（2）班', "张兰","陈心璇", "24cm"],
          ['2021年', '五年级（3）班', "赵晓峰","安逸", "22cm"],
          ['2020年', '五年级（1）班', "张琪","陈心璇", "25cm"],
          ['2019年', '六年级（5）班', "赵晓峰","戴熠默", "26cm"],
          ['2018年', '三年级（3）班', "张兰","董佳琪", "21cm"],
          ['2017年', '四年级（2）班', "张琪","韩尚希", "22cm"],
          ['2016年', '五年级（4）班', "胡小兰","何姝菡", "23cm"],
        ],
        rowNum: 5, //表格行数
        headerHeight: 54,
        headerBGC: '#072652', //表头
        oddRowBGC:'#06153c',
        evenRowBGC:'#06153c',
         align: ['left','left'],
        columnWidth: [94,160],
      }},{title: '立定跳远历年最好成绩',config: {
        header: ['<span style="color:#9abeff;font-weight:bold">年份</span>', 
        '<span style="color:#9abeff;font-weight:bold;">所在班级</span>', 
        '<span style="color:#9abeff;font-weight:bold">指导老师</span>',
        '<span style="color:#9abeff;font-weight:bold">学生姓名</span>',
        '<span style="color:#9abeff;font-weight:bold">成绩</span>'],
        data: [
          ['2023年', '五年级（3）班', "赵晓峰","胡蝶", "1.64米"],
          ['2022年', '三年级（2）班', "张兰","李朵", "1.7米"],
          ['2021年', '六年级（3）班', "张琪","李牧瑶", "1.68米"],
          ['2020年', '六年级（1）班', "张琪","李奕诺", "1.62米"],
          ['2019年', '四年级（5）班', "赵晓峰","任子川", "1.61米"],
          ['2018年', '五年级（3）班', "张琪","赵梓潼", "1.65米"],
          ['2017年', '五年级（2）班', "张琪","申钰淇", "1.62米"],
          ['2016年', '三年级（4）班', "张兰","赵奕莹", "1.6米"],
        ],
        rowNum: 5, //表格行数
        headerHeight: 54,
        headerBGC: '#072652', //表头
        oddRowBGC:'#06153c',
        evenRowBGC:'#06153c',
         align: ['left','left'],
        columnWidth: [94,160],
      }},{title: '50米跑历年最好成绩',config: {
        header: ['<span style="color:#9abeff;font-weight:bold">年份</span>', 
        '<span style="color:#9abeff;font-weight:bold;">所在班级</span>', 
        '<span style="color:#9abeff;font-weight:bold">指导老师</span>',
        '<span style="color:#9abeff;font-weight:bold">学生姓名</span>',
        '<span style="color:#9abeff;font-weight:bold">成绩</span>'],
        data: [
          ['2023年', '五年级（3）班', "赵晓峰","魏祎", "11秒"],
          ['2022年', '三年级（2）班', "张兰","许梦棋", "13秒21"],
          ['2021年', '六年级（3）班', "张琪","薛善溪", "11秒12"],
          ['2020年', '六年级（1）班', "张琪","杨谨帆", "10秒34"],
          ['2019年', '四年级（5）班', "赵晓峰","陈语萌", "13秒1"],
          ['2018年', '五年级（3）班', "张琪","刘仕娜", "12秒31"],
          ['2017年', '五年级（2）班', "张琪","王文谦", "12秒1"],
          ['2016年', '三年级（4）班', "张兰","赵梓瑶", "13秒23"],
        ],
        rowNum: 5, //表格行数
        headerHeight: 54,
        headerBGC: '#072652', //表头
        oddRowBGC:'#06153c',
        evenRowBGC:'#06153c',
         align: ['left','left'],
        columnWidth: [94,160],
      }},{title: '800米跑历年最好成绩',config: {
        header: ['<span style="color:#9abeff;font-weight:bold">年份</span>', 
        '<span style="color:#9abeff;font-weight:bold;">所在班级</span>', 
        '<span style="color:#9abeff;font-weight:bold">指导老师</span>',
        '<span style="color:#9abeff;font-weight:bold">学生姓名</span>',
        '<span style="color:#9abeff;font-weight:bold">成绩</span>'],
        data: [
          ['2023年', '五年级（3）班', "赵晓峰","陈默", "3分15秒2"],
          ['2022年', '三年级（2）班', "张兰","高子瑾", "3分05秒"],
          ['2021年', '六年级（3）班', "张琪","高彬泽", "2分56秒35"],
          ['2020年', '六年级（1）班', "张琪","郭鑫垚", "2分48秒57"],
          ['2019年', '四年级（5）班', "赵晓峰","田佳浩", "3分13秒34"],
          ['2018年', '五年级（3）班', "张琪","李嘉毅", "2分35秒2"],
          ['2017年', '五年级（2）班', "张琪","王浩阳", "2分42秒12"],
          ['2016年', '三年级（4）班', "张兰","郭静溪", "2分35秒23"],
        ],
        rowNum: 5, //表格行数
        headerHeight: 54,
        headerBGC: '#072652', //表头
        oddRowBGC:'#06153c',
        evenRowBGC:'#06153c',
         align: ['left','left'],
        columnWidth: [94,160],
      }}]
      
    }
  },
  components: {
   
  },
  mounted() {
    this.cancelLoading()
    
  },
  beforeDestroy () {
    clearInterval(this.timing)
  },
  methods: {
  //  加载动画
    cancelLoading() {
      setTimeout(() => {
        this.loading = false
      }, 500)
    },

   
    randomExtend (minNum, maxNum) {
      if (arguments.length === 1) {
        return parseInt(Math.random() * minNum + 1, 10)
      } else {
        return parseInt(Math.random() * (maxNum - minNum + 1) + minNum, 10)
      }
    },
     returnBack(){
      this.$router.go(-1)
    },

  }
}
</script>

<style lang="scss" scoped>
.title{
  padding: 30px 24px;
    font-size: 22px;
    color: #12cfd8;
}
.bg{
  padding: 0;
}
.ejcon {
  color: #d3d6dd;
  width: 1920px;
  height: 1080px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transform-origin: left top;
  overflow: hidden;

  .host-body {
.top-header {
  position: relative;
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: space-between;
  flex-shrink: 0;
  padding: 16px;
  margin-bottom: 16px;

  .header-center-decoration {
    width: 46%;
    height: 60px;
    margin-top: 20px;
  }

  .header-left-decoration, .header-right-decoration {
    width: 25%;
    height: 60px;
  }

  .center-title {
    position: absolute;
    font-size: 30px;
    font-weight: bold;
    left: 50%;
    top: 28px;
    transform: translateX(-50%);
    letter-spacing:8px;
    color: #0ebe9b;
  }
}
.return{
  position: fixed;
  z-index: 9999;
  cursor: pointer;
  left: 20px;
  top: 52px;
  width: 80px;
  height: 80px;
  background: rgba(0, 250, 255, 0.7);
  border-radius: 50%;
  border: 1px solid #0f319b;
  img{
    display: block;
    text-align: center;
    width: 36px;
    margin: 24px auto;
  }
}
  }

  
.classInfo{
  margin: 0 20px;
  >div:nth-child(3n){
    margin-right: 0;
  }
  >div:nth-child(-n+3){
    margin-bottom: 36px;
  }
  .classInfo_Item{
    width: 610px;
    height: 440px;
    float: left;
    font-size: 20px ;
    margin-right: 20px;
      .dv-scr-board {
       padding: 0 20px;
    width: 609px;
    height: 352px;
      color: #d4eaf6;
      ::v-deep .header-item{
        font-size: 18px !important;
      }
      ::v-deep .row-item{
        height: 58px !important;
        line-height:58px !important;
        font-size: 18px !important;
        color: #9abeff !important;
      }
      
    }
    
  }
}

}
</style>
